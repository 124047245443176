<template>
  <Header />
  <div
    class="indoor-background"
    :style="{
      backgroundImage:
        'url(' + require(`@/assets/image/products.jpg`) + ')',
    }"
  >
    <p>PRODUCTS</p>
  </div>
  <p class="title">{{ itemsBanners.title }}</p>
  <div class="product_carousel">
    <MDBCarousel
      v-model="carousel"
      :items="itemsBanners.data"
      :indicators="true"
      class="slide-banner-product"
      v-if="itemsBanners.data"
      controls
    />
  </div>
  <br /><br />
  <div class="col-example" style="width: 70%; margin: 0px auto">
    <p>{{ itemsBanners.description }}</p>
  </div>
  <br />
  <Footer />
</template>
<script>
import Header from "@/views/Header.vue";
import Footer from "@/views/Footer.vue";
import { MDBCarousel } from "mdb-vue-ui-kit";
const CAROUSEL_INTERVAL = 5000;
export default {
  name: "Indoor",
  components: { Header, Footer, MDBCarousel },
  data() {
    return {
      itemsBanners: {
        title: "ROLLER BLINDS",
        description:
          "are practical and affordable. Choose or use combination a wide variety of 100% blockout, sheer and light filtering fabrics to achieve the highest level of light control and UV/heat protection. Our Roller Blind system is chain driven that allows the up & down operation to be easy and smoothly. Chains are available in metal and stainlesssteel which are even stronger lasting than plastics. Alternatively, Roller Blind can be motorized and operated via remote control or wall switch. The aluminum bottom rail in a wide range of colours is available to enhance the perfect look of the blind",
        data: [
          {
            src: require("@/assets/gallery/roller/r-1.jpg"),
            interval: CAROUSEL_INTERVAL,
          },
          {
            src: require("@/assets/gallery/roller/r-2.jpg"),
            interval: CAROUSEL_INTERVAL,
          },
          {
            src: require("@/assets/gallery/roller/r-3.jpg"),
            interval: CAROUSEL_INTERVAL,
          },
          {
            src: require("@/assets/gallery/roller/r-4.jpg"),
            interval: CAROUSEL_INTERVAL,
          },
          {
            src: require("@/assets/roller-blinds/rb-1.jpg"),
            interval: CAROUSEL_INTERVAL,
          },
          {
            src: require("@/assets/roller-blinds/rb-2.jpg"),
            interval: CAROUSEL_INTERVAL,
          },
          {
            src: require("@/assets/roller-blinds/rb-3.jpg"),
            interval: CAROUSEL_INTERVAL,
          },
          {
            src: require("@/assets/roller-blinds/rb-4.jpg"),
            interval: CAROUSEL_INTERVAL,
          },
          {
            src: require("@/assets/roller-blinds/rb-5.jpg"),
            interval: CAROUSEL_INTERVAL,
          },
          {
            src: require("@/assets/roller-blinds/rb-6.jpg"),
            interval: CAROUSEL_INTERVAL,
          },
        ],
      },
      key: 0,
    };
  },
  mounted() {
    this.getListCarousel();
  },
  watch: {
    "$route.params.id": function () {
      this.getListCarousel();
    },
  },
  methods: {
    getListCarousel() {
      switch (this.$route.params.id) {
        case "roller_blinds":
          this.itemsBanners = {
            title: "ROLLER BLINDS",
            description:
              "are practical and affordable. Choose or use combination a wide variety of 100% blockout, sheer and light filtering fabrics to achieve the highest level of light control and UV/heat protection. Our Roller Blind system is chain driven that allows the up & down operation to be easy and smoothly. Chains are available in metal and stainlesssteel which are even stronger lasting than plastics. Alternatively, Roller Blind can be motorized and operated via remote control or wall switch. The aluminum bottom rail in a wide range of colours is available to enhance the perfect look of the blind.",
            data: [
              {
                src: require("@/assets/gallery/roller/r-1.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
              {
                src: require("@/assets/gallery/roller/r-2.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
              {
                src: require("@/assets/gallery/roller/r-3.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
              {
                src: require("@/assets/gallery/roller/r-4.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
              {
                src: require("@/assets/roller-blinds/rb-1.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
              {
                src: require("@/assets/roller-blinds/rb-2.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
              {
                src: require("@/assets/roller-blinds/rb-3.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
              {
                src: require("@/assets/roller-blinds/rb-4.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
              {
                src: require("@/assets/roller-blinds/rb-5.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
              {
                src: require("@/assets/roller-blinds/rb-6.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
            ],
          };
          break;
        case "vertical_blinds":
          this.itemsBanners = {
            title: "VERTICAL BLINDS",
            description:
              "are elegant and durable. Available in a wide range of colours and fabrics, vertical blinds are a smart choice for sliding door and large windows as the blades can be pulled to either side or drawn all to one side to allow optimum light. Simplicity in operation, low maintenance and easy to clean are add-on feature of the vertical blinds.",
            data: [
              {
                src: require("@/assets/gallery/vertical/v-1.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
              {
                src: require("@/assets/gallery/vertical/v-2.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
            ],
          };
          break;
        case "roman_blinds":
          this.itemsBanners = {
            description:
              "have the most elegant and classic look. A vast selection of colour, design and texture in fabrics is available to suit individual taste and every application. Cord-lock operation with wall-mounted cleat is preferred whereas chain drive with Metal Chain and Aluminium head rail can be as another option for ease of operation. Remote control motorised operation is also available for the convenient choice.",
            title: "ROMAN BLINDS",
            data: [
              {
                src: require("@/assets/gallery/roman/rob-1.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
              {
                src: require("@/assets/gallery/roman/rob-2.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
              {
                src: require("@/assets/gallery/roman/rob-3.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
            ],
          };
          break;
        case "venetian_blinds":
          this.itemsBanners = {
            title: "VENETIAN BLINDS",
            description:
              "Our quality range of Venetian blinds provide you the option to choose from the most fashionable basswood timber, aluminium or timber-look material, a vast of beautiful colours and a variety of different slat widths to suit a variety of home environments.",
            data: [
              {
                src: require("@/assets/gallery/venetian/v-1.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
              {
                src: require("@/assets/gallery/venetian/v-2.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
            ],
          };
          break;
        case "panel_glide_blind":
          this.itemsBanners = {
            title: "PANEL GLIDE BLINDS",
            description:
              "Panel blinds are a stylish modern alternative to vertical blinds and are an ideal choice for sliding doors and wide windows, and can also used as perfect room divider.",
            data: [
              {
                src: require("@/assets/panel/p-1.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
            ],
          };
          break;
        case "wave_fold":
          this.itemsBanners = {
            title: "S-FOLD/WAVE-FOLD",
            description:
              "Curtains offer a diverse range of fabrics, colours, prints and floral. There are also no limitations on styles and shapes, from elegant and formal styles to pretty and casual to suit any room. Adding on the decorator rods, pelmets, swags and tails, curtains are luxurious and bring a sense of style to any home.",
            data: [
              {
                src: require("@/assets/wave/w-1.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
              {
                src: require("@/assets/s-fold/sf-1.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
              {
                src: require("@/assets/s-fold/sf-2.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
              {
                src: require("@/assets/s-fold/sf-3.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
            ],
          };
          break;
        case "pleated":
          this.itemsBanners = {
            title: "PLEATED",
            description:
              "Curtains offer a diverse range of fabrics, colours, prints and floral. There are also no limitations on styles and shapes, from elegant and formal styles to pretty and casual to suit any room. Adding on the decorator rods, pelmets, swags and tails, curtains are luxurious and bring a sense of style to any home.",
            data: [
              {
                src: require("@/assets/pleated/p-1.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
            ],
          };
          break;
        case "eyelet":
          this.itemsBanners = {
            title: "EYELET",
            description:
              "Curtains offer a diverse range of fabrics, colours, prints and floral. There are also no limitations on styles and shapes, from elegant and formal styles to pretty and casual to suit any room. Adding on the decorator rods, pelmets, swags and tails, curtains are luxurious and bring a sense of style to any home.",
            data: [
              {
                src: require("@/assets/eyelet/e-1.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
            ],
          };
          break;
        case "plantation_shutters":
          this.itemsBanners = {
            title: "PLANTATION SHUTTERS",
            description:
              "Curtains offer a diverse range of fabrics, colours, prints and floral. There are also no limitations on styles and shapes, from elegant and formal styles to pretty and casual to suit any room. Adding on the decorator rods, pelmets, swags and tails, curtains are luxurious and bring a sense of style to any home",
            data: [
              {
                src: require("@/assets/gallery/palentino/pl-1.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
              {
                src: require("@/assets/gallery/palentino/pl-2.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
              {
                src: require("@/assets/gallery/palentino/pl-3.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
              {
                src: require("@/assets/plantation-shutter/ps-1.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
              {
                src: require("@/assets/plantation-shutter/ps-2.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
              {
                src: require("@/assets/plantation-shutter/ps-3.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
              {
                src: require("@/assets/plantation-shutter/ps-4.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
            ],
          };
          break;
        case "zipscreen":
          this.itemsBanners = {
            title: "ZIPAWNINGS",
            description:
              "The ZipAwning System is the ultimate external screening solution for any outdoor areas and windows. Specifically designed for alfresco entertaining areas such as pergolas, verandahs and balconies, it offers privacy and protection from the sun, wind, rain and insects all year around.",
            data: [
              {
                src: require("@/assets/gallery/screen/zs-1.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
              {
                src: require("@/assets/gallery/screen/zs-2.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
            ],
          };
          break;
        case "folding":
          this.itemsBanners = {
            title: "FOLDING",
            description:
              "Unlikely other awning designs, the Folding Arm Awning extends horizontally and not vertically, therefore, it is the ideal to be used for decks, patios and balconies. Folding arm awnings can be operated manually using a crank handle. Alternatively, they can be operated by electric powered motors, controlled by hand held remote or by wall mounted switch. Optional sun, rain and wind sensors can be incorporated for total ease of use.",
            data: [
              {
                src: require("@/assets/gallery/folding/f-1.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
              {
                src: require("@/assets/gallery/folding/f-2.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
            ],
          };
          break;
        case "awnings":
          this.itemsBanners = {
            title: "AWNINGS",
            description:
              "is used to protect your house and family from harsh Melbourne climate by eliminating sunlight, heat and glare on to your window. Our custom made awnings offer you all kinds of delightful design and finishing options to suit your specific needs. You can choose from the finest quality canvas, sunscreen or acrylic materials in a traditional striped designs or plain fashion colour. You can select from a wide range of styles : the traditional automatic awnings, straight drop awnings, fixed guide awnings, wire-guide awnings. Those awnings can be operated either manually, with rope and pulley or crank-wider. You can also choose the motorised awnings for a completed ease of use.",
            data: [
              {
                src: require("@/assets/gallery/folding/f-1.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
              {
                src: require("@/assets/gallery/folding/f-2.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
            ],
          };
          break;
        case "roller_shutters":
          this.itemsBanners = {
            title: "ROLLER SHUTTERS",
            description:
              "The roller shutters offer the security, privacy, heat and cold control as well as the total light control. Our roller shutter range is designed for use on windows, sliding doors, bay windows, corner windows and other applications. We currently have 15 colour range available for choice.",
            data: [
              {
                src: require("@/assets/shutter/shutter-1.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
              {
                src: require("@/assets/shutter/shutter-2.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
            ],
          };
          break;
        case "security_door":
          this.itemsBanners = {
            title: "SECURITY DOORS/FLY DOORS",
            description:
              "Screen doors provide a visual deterrent and effective protection against uninvited guests and bothersome bugs. They can be hinged (security doors) or sliding (fly doors). The wide range of grilles in colours and shapes are available to be fitted into all doors as the add-on safety and charming to the house. Mesh options include fiberglass, tuffs, aluminum and stainless steel.",
            data: [
              {
                src: require("@/assets/gallery/security/gallery1.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
              {
                src: require("@/assets/gallery/security/gallery2.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
              {
                src: require("@/assets/gallery/security/gallery3.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
              {
                src: require("@/assets/gallery/security/gallery4.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
            ],
          };
          break;
        case "security_windows":
          this.itemsBanners = {
            title: "SECURITY WINDOWS",
            description:
              "Security window is non-removable; it offers security for the window openings. It is more economical option compared to the window roller shutters.",
            data: [
              {
                src: require("@/assets/gallery/security/gallery1.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
              {
                src: require("@/assets/gallery/security/gallery2.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
              {
                src: require("@/assets/gallery/security/gallery3.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
              {
                src: require("@/assets/gallery/security/gallery4.jpg"),
                interval: CAROUSEL_INTERVAL,
              },
            ],
          };
          break;
        case "fly_screen":
          this.itemsBanners = {
            title: "FLY SCREENS",
            description:
              "Fly Screens are the perfect way to stay bug-free and can be fitted to many different types of windows and openings. Frames are 9mm or 11mm in thickness and available in a wide range of standard colorbond colors or can be powder coated in the colour of your choice to match or complement existing window frames at the additional price. Mesh options include fiberglass, tuffs, aluminum and stainless steel.",
            data: [
              {
                src: require("@/assets/fly/fs-1.jpg"),
                interval: CAROUSEL_INTERVAL,
              }
            ],
          };
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss">
.slide-banner-product {
  img {
    width: 1000px !important;
    max-height: 600px !important;
    margin: 0px auto;
  }
}
</style>
<style lang="scss" scoped>
.product_carousel {
  min-height: 400px;
  width: 100%;
}
@media screen and (min-width: 1000px) {
  .product_carousel {
    width: 1000px;
    margin: 0px auto;
  }
}
.title {
  text-align: center;
  font-size: 50px;
  font-weight: 100;
}
.indoor-background {
  height: 300px;
  margin-bottom: 20px;
  position: relative;
  p {
    position: absolute;
    top: 40%;
    right: 43%;
    color: #ffffff;
    font-size: 50px;
    font-weight: 100;
  }
}
.col-example {
  .title {
    margin-top: 20px;
    font-weight: 500;
  }
  p {
    font-weight: 100;
  }
}
</style>